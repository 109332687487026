import { COOKIES, CURRENCIES } from '~/constants'
import type { Nullable } from '~/types'

export default function useCurrency() {
  const runtimeConfig = useRuntimeConfig()

  const preferredCurrencyCookie = useCookie(COOKIES.currency, {
    domain: runtimeConfig.public.cookieDomain,
  })

  function updatePreferredCurrency(currency: Nullable<CURRENCIES>) {
    preferredCurrencyCookie.value = currency?.toString() || undefined
  }

  const selectedCurrencyCode = computed(() => preferredCurrencyCookie.value || CURRENCIES.USD)

  return {
    selectedCurrencyCode,
    preferredCurrency: preferredCurrencyCookie,
    updatePreferredCurrency,
  }
}
